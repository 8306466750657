import React from 'react';
import { twMerge } from 'tailwind-merge';
import CustomizedButton from '@/components/WidgetMaker/WidgetDnD/WIdgetPropertiesBasic/components/Button';
import {
  BUTTON_SIZE_TYPE,
  BUTTON_TYPE,
} from 'src/components/WidgetMaker/utils/buttonConstant';
import { btnActionHandler } from '@/components/DesignSystem/ModernTheme/utils/btnUtils';
import { getButtonVariantsData } from '../../../Carousel/utils';

export function HeaderButtonsLayout({ isMobile, activeHeaderData }) {
  if (isMobile || !activeHeaderData?.headerPropsConfig?.buttons?.length) {
    return null;
  }
  return (
    <div className={twMerge(`tw-flex tw-items-center tw-gap-[24px]`)}>
      {activeHeaderData?.headerPropsConfig?.buttons?.map((buttonData, btnIndex) => {
        const buttonVariant = getButtonVariantsData(buttonData);
        const selectedButtonType = buttonVariant[buttonData.type];

        return buttonData.isShow ? (
          <CustomizedButton
            key={btnIndex}
            size={BUTTON_SIZE_TYPE.SMALL}
            btnType={buttonData.type}
            buttonData={buttonData}
            selectedButtonData={selectedButtonType}
            variant={selectedButtonType.variant}
            onClick={() => btnActionHandler(buttonData?.actions, () => {})}
            className={twMerge(
              `!tw-w-auto !tw-rounded-[0px] !tw-py-[16px] !tw-pb-[10px] !tw-pt-[10px] !tw-text-[14px] !tw-font-medium !tw-leading-[20px] tw-text-newBlack [&>p]:!tw-text-ellipsis [&>p]:!tw-whitespace-nowrap ${buttonData.type === BUTTON_TYPE.TEXTUAL ? '!tw-pb-[0px] !tw-pt-[0px]' : ''}`
            )}
          >
            {buttonData?.text}
          </CustomizedButton>
        ) : null;
      })}
    </div>
  );
}
