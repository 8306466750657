import { useRef, useState } from 'react';
import { getIconSizeAccToConfig } from './getSizeAccType';
import { DynamicComponent } from './dynamicComponent';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { AccordionCloseIcon, AccordionOpenIcon } from '@/components/MegaMenu/svgIcons';
import { NavigationDropdown } from '@/components/DesignSystem/Drawer/NewDropdown';
import classNames from 'classnames';
import { MenuDrawerMobile } from '@/components/DesignSystem/Drawer/MenuDrawerMobile';
import { MegaMenuDrawerDesktop } from '@/components/DesignSystem/Drawer/MegaMenuDrawerDesktop';
import Burger from '@/components/Common/ReactHamburger';
import { IS_SERVER } from '@/utils/checkRenderEnv';
import { showMegaMenu } from '@/utils/showMegaMenu';
import { useGetDeviceType } from '@/hooks/useGetDeviceType';
import { getRoute } from 'src/utils/routes';
import { useSSRSelector } from 'src/redux';
import { useRouter } from 'next/router';
import { useNavlinkStyling } from 'src/hooks/useNavlinkStyling';
import { getHamburgerCustomList, useGetNavlinkList } from '../utils';
import { useGetCategoriesList } from 'src/components/WidgetMaker/WidgetDnD/WidgetProperties/HeaderBuilder/components/utils';

const TWO_LINE_HEADER = ['header3', 'header4'];

function hamburgerIcon(color = '', size) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M21 6.75H3C2.80109 6.75 2.61032 6.67098 2.46967 6.53033C2.32902 6.38968 2.25 6.19891 2.25 6C2.25 5.80109 2.32902 5.61032 2.46967 5.46967C2.61032 5.32902 2.80109 5.25 3 5.25H21C21.1989 5.25 21.3897 5.32902 21.5303 5.46967C21.671 5.61032 21.75 5.80109 21.75 6C21.75 6.19891 21.671 6.38968 21.5303 6.53033C21.3897 6.67098 21.1989 6.75 21 6.75ZM21.75 12C21.75 11.8011 21.671 11.6103 21.5303 11.4697C21.3897 11.329 21.1989 11.25 21 11.25H3C2.80109 11.25 2.61032 11.329 2.46967 11.4697C2.32902 11.6103 2.25 11.8011 2.25 12C2.25 12.1989 2.32902 12.3897 2.46967 12.5303C2.61032 12.671 2.80109 12.75 3 12.75H21C21.1989 12.75 21.3897 12.671 21.5303 12.5303C21.671 12.3897 21.75 12.1989 21.75 12ZM12.75 18C12.75 17.8011 12.671 17.6103 12.5303 17.4697C12.3897 17.329 12.1989 17.25 12 17.25H3C2.80109 17.25 2.61032 17.329 2.46967 17.4697C2.32902 17.6103 2.25 17.8011 2.25 18C2.25 18.1989 2.32902 18.3897 2.46967 18.5303C2.61032 18.671 2.80109 18.75 3 18.75H12C12.1989 18.75 12.3897 18.671 12.5303 18.5303C12.671 18.3897 12.75 18.1989 12.75 18Z"
        fill={color}
      />
    </svg>
  );
}

export const NavLinkRenderer = (props: any) => {
  const {
    activeHeaderData,
    headerData,
    dynamicSectionData,
    headerNodeRef,
    isBuilder,
    componentData,
    base_config,
    currentClass,
    globalClass,
    showDynamicSection = false,
    headerRef,
    headerName,
    // globalStyleObject,
    isScroll,
  } = props;
  const router = useRouter();
  const { deviceType } = useGetDeviceType();
  const isMobile = deviceType === 'mobile';
  const [showDropdownIndex, setShowDropdownIndex] = useState(-1);
  const [state, setState] = useState({
    openMenuDrawerOnMobile: false,
    openDropdownOnDesktop: false,
    openMegaMenuOnDesktop: false,
  });
  const { store, categories } = useSSRSelector((state) => ({
    store: state.storeReducer.store,
    categories: state.catalogReducer.categories,
  }));
  const categoriesList = useGetCategoriesList();

  const linkListRefs = useRef([]);

  const isTransparent =
    globalClass?.staticClass?.base_config?.defaultFillColorType === 'transparent';
  const selectedClass = isTransparent ? currentClass : globalClass?.staticClass;
  const containerRef = useRef(null);

  const megaMenuStyles = {
    background: !isTransparent
      ? globalClass?.staticClass?.base_config?.defaultBackgroundColor
      : isTransparent
        ? globalClass?.scrollClass?.base_config?.defaultBackgroundColor
        : '#fff',
  };
  const { navlinkList } = useGetNavlinkList({
    activeHeaderData,
    headerData,
    dynamicSectionData,
    headerNodeRef,
    isBuilder,
    componentData,
    headerName,
    containerRef,
  });

  // For parent
  const { onLinkHoverEnter, onLinkHoverLeave, getNavElementStyle, hoverIndex } =
    useNavlinkStyling({ isScrollable: isScroll });
  // For dropdown
  const customItemConfigs = useNavlinkStyling();

  const toggleMenuDrawerOnMobile = (val = undefined) =>
    setState((x) => ({ ...x, openMenuDrawerOnMobile: !x.openMenuDrawerOnMobile }));

  const navItemClick = (link: string, openInNewTab = false) => {
    if (openInNewTab) {
      window.open(link, '_blank');
    } else {
      router.push(getRoute(link, store?.store_info?.domain));
    }
  };

  // const toggleDropdownOnDesktop = () =>
  //   setState((x) => ({ ...x, openDropdownOnDesktop: !x.openDropdownOnDesktop }));

  // const toggleMegaMenuOnDesktop = () =>
  //   setState((x) => ({ ...x, openMegaMenuOnDesktop: !x.openMegaMenuOnDesktop }));

  function getUrl() {
    const pathName = window?.location?.pathname;
    if (!pathName?.includes(store?.store_info?.domain)) return pathName;
    const updatedPathName = pathName?.split(store?.store_info?.domain);
    return updatedPathName[1] || '/';
  }

  function getHoverTextColor() {
    const type = selectedClass.base_config.defaulthoverType;
    switch (type) {
      case 'Underline':
        return selectedClass.base_config.defaultUnderlineTextColor;
      case 'Background Box':
        return selectedClass.base_config.defaultBoxTextColor;
      case 'None':
        return selectedClass?.base_config?.defaultNavLinksColor;
    }
  }

  function checkForUnderline() {
    if (selectedClass.base_config.defaulthoverType === 'Underline') {
      if (selectedClass.base_config.defaultUnderLineType === 'above') {
        return 'above';
      } else {
        return 'below';
      }
    }
    return '';
  }

  const onMenuDrawerItemClick = (action, page) => {
    switch (action) {
      case 'whatsapp':
        window.open(
          `https://api.whatsapp.com/send?phone=+91${
            store?.display_phone || store?.owner?.phone
          }&text=Hi%2C%0D%0A%0D%0AI+am+at+your+online+store+and+need+some+help.%0D%0A%0D%0A${
            store?.store_info?.store_url
          }`
        );
        break;
      case 'redirect':
        navItemClick(page);
        break;
      default:
        break;
    }
    toggleMenuDrawerOnMobile(false);
  };

  const _RenderUnderlineComponent = (index = -1, customStyle = {}) => (
    <div
      className={`${checkForUnderline() ? 'underline-comp' : ''} tw-absolute tw-z-10 tw-hidden tw-h-[1px] tw-rounded-[0.5px]`}
      style={{
        width: 'calc(100% - 10px)',
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: selectedClass.base_config.defaultUnderlineColor,
        height: checkForUnderline() === 'below' ? '3px' : '1px',
        bottom: checkForUnderline() === 'above' ? -1 : '',
        top:
          checkForUnderline() === 'below'
            ? headerRef?.current?.clientHeight -
              linkListRefs.current[index]?.offsetTop -
              3
            : '',
        ...customStyle,
      }}
    />
  );

  function getDropdownList(isShopComponent, option) {
    if (isBuilder) {
      return option;
    }
    if (!isShopComponent) {
      return option;
    }
    // Extracting newly added categories
    const newCatList = Object.values(categoriesList)?.filter((item: any) => {
      const isOldCat = !!option.find(
        (cat) => (item.id && cat.id === item.id) || cat.name === item.name
      );
      return !isOldCat;
    });

    return option?.concat(newCatList);
  }

  function renderLinks(hamburgerMenu = false) {
    return (
      <div
        style={{ justifyContent: base_config.defaultPosition }}
        className={`nav-links tw-flex tw-cursor-pointer tw-items-center ${hamburgerMenu ? 'tw-flex-col tw-gap-y-[20px]' : ''} hover:tw-text-[red]`}
      >
        {navlinkList?.map((item, index: number) => {
          const {
            name,
            redirectionLink,
            showOnHeader,
            type,
            option,
            isShopComponent,
            redirectNewPage = false,
          } = item;
          const isTypeDropdown = type === 'dropdown';

          const isDropdownOpened =
            isShopComponent && showMegaMenu()
              ? state.openMegaMenuOnDesktop
              : showDropdownIndex === index;

          const updatedLink = redirectionLink?.startsWith('/')
            ? redirectionLink
            : `/${redirectionLink}`;

          const isNavLinkActive = !IS_SERVER
            ? getUrl() === updatedLink && !isTypeDropdown
            : false;

          const dropDownList = getDropdownList(isShopComponent, option);

          return (
            showOnHeader && (
              <ClickAwayListener onClickAway={() => setShowDropdownIndex(-1)}>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    if (isTypeDropdown) {
                      if (isShopComponent && showMegaMenu()) {
                        setShowDropdownIndex(-1);
                        setState((oldState) => ({
                          ...oldState,
                          openMegaMenuOnDesktop:
                            showDropdownIndex === index
                              ? false
                              : !oldState.openMegaMenuOnDesktop,
                        }));
                      } else {
                        setShowDropdownIndex(showDropdownIndex === index ? -1 : index);
                        setState((oldState) => ({
                          ...oldState,
                          openMegaMenuOnDesktop: false,
                        }));
                      }
                    }
                  }}
                  ref={(ref) => (linkListRefs.current[index] = ref)}
                  key={name}
                  style={getNavElementStyle(index)}
                  className={classNames(
                    'tw-relative tw-m-[10px] tw-flex tw-items-center [&>.underline-comp]:hover:tw-block',
                    (isDropdownOpened || isNavLinkActive) &&
                      '[&>.underline-comp]:tw-block',
                    !!redirectionLink &&
                      !!(dropDownList?.length || isNavLinkActive) &&
                      'tw-pointer-events-none'
                    )}
                  >
                    <div
                      className=" tw-flex tw-items-center  tw-px-[5px] tw-py-[6px]"
                      onMouseEnter={() => onLinkHoverEnter(index)}
                      onMouseLeave={onLinkHoverLeave}
                    >
                      {redirectionLink ? (
                        <div
                          onClick={() => navItemClick(redirectionLink, redirectNewPage)}
                        >
                          <p
                            className="tw-m-0 tw-select-none	tw-whitespace-nowrap tw-break-keep tw-tracking-[0.9px] "
                            style={{ color: 'inherit', textDecoration: 'none' }}
                          >
                            {name}
                          </p>
                        </div>
                      ) : (
                        <span className="tw-select-none tw-tracking-[0.9px] ">
                          {name}
                        </span>
                      )}
                      {isTypeDropdown ? (
                        <div className="tw-ml-[7px] tw-flex tw-items-center ">
                          {isDropdownOpened ? (
                            <AccordionCloseIcon
                              secondaryColor={
                                hoverIndex === index
                                  ? getHoverTextColor()
                                  : selectedClass.base_config.defaultNavLinksColor
                              }
                            />
                          ) : (
                            <AccordionOpenIcon
                              secondaryColor={
                                hoverIndex === index
                                  ? getHoverTextColor()
                                  : selectedClass.base_config.defaultNavLinksColor
                              }
                            />
                          )}
                        </div>
                      ) : null}
                    </div>

                  {isTypeDropdown &&
                  isDropdownOpened &&
                  !(isShopComponent && showMegaMenu()) ? (
                    <NavigationDropdown
                      optionsList={dropDownList}
                      onNavItemClick={navItemClick}
                      isShopComponent={isShopComponent}
                      categories={categories}
                      wrapperClass="tw-font-dmSans "
                      dropDownStyle={{
                        background: !isTransparent
                          ? globalClass?.staticClass?.base_config?.defaultBackgroundColor
                          : isTransparent
                            ? globalClass?.scrollClass?.base_config
                                ?.defaultBackgroundColor
                            : '#fff',
                      }}
                      customItemConfigs={customItemConfigs}
                      top={
                        isTransparent
                          ? headerRef?.current?.clientHeight -
                            (linkListRefs.current[index]?.offsetTop +
                              (TWO_LINE_HEADER?.includes(headerName) ? 3 : 20))
                          : headerRef?.current?.clientHeight -
                            linkListRefs.current[index]?.offsetTop
                        // linkListRefs.current[index]?.offsetHeight
                      }
                      _RenderUnderlineComponent={_RenderUnderlineComponent}
                    />
                  ) : null}
                  {_RenderUnderlineComponent?.(index)}
                </div>
              </ClickAwayListener>
            )
          );
        })}
      </div>
    );
  }
  function renderHamBurgerMenu() {
    const desktopIconConfig = globalClass.staticClass.base_config.defaultFontSizeType;
    const mobileIconConfig =
      globalClass.staticClass.base_config.defaultFontSizeTypeMobile;
    const sizeDetails = getIconSizeAccToConfig(
      isMobile ? mobileIconConfig : desktopIconConfig
    );
    const customItemsList = getHamburgerCustomList(
      activeHeaderData,
      onMenuDrawerItemClick,
      toggleMenuDrawerOnMobile
    );

    return (
      <div className="tw-min-w-max tw-flex-shrink-0">
        <div style={{ width: 30 }}>
          <Burger
            style={{
              width: 23,
            }}
            open={state.openMenuDrawerOnMobile}
            setOpen={toggleMenuDrawerOnMobile}
            theme={'#111C36'}
            showIcon={() =>
              hamburgerIcon(selectedClass.base_config.defaultNavLinksColor, sizeDetails)
            }
            showDiffIcon={true}
          />
        </div>
        {/* {state.openMenuDrawerOnMobile && (
          <div
            style={{ border: '1px solid black' }}
            className="tw-absolute tw-z-[10000] tw-mt-[10px] tw-bg-white tw-p-[20px]"
          >
            {renderLinks(true)}
          </div>
        )} */}
        <MenuDrawerMobile
          isVisible={state.openMenuDrawerOnMobile}
          closeDrawer={toggleMenuDrawerOnMobile}
          renderList={componentData}
          fromNewHeader
          customItemList={customItemsList}
        />
      </div>
    );
  }

  return (
    <div
      ref={containerRef}
      className={`tw-flex tw-items-center ${isMobile ? 'tw-gap-[16px]' : 'tw-gap-[20px]'} ${base_config.customClass} !tw-w-[auto] ${base_config.type === 'hamburger' ? '' : 'tw-justify-center'}`}
    >
      {base_config.type === 'hamburger' ? renderHamBurgerMenu() : renderLinks()}
      {showDynamicSection && (
        <div>
          <DynamicComponent currentClass={selectedClass} globalClass={globalClass} />
        </div>
      )}
      {state.openMegaMenuOnDesktop && (
        <MegaMenuDrawerDesktop
          open={state.openMegaMenuOnDesktop}
          onClose={() => setState((x) => ({ ...x, openMegaMenuOnDesktop: false }))}
          forNewHeader
          customStylist={{
            megaMenuStyles,
            isScroll,
          }}
          _RenderUnderlineComponent={_RenderUnderlineComponent}
        />
      )}
    </div>
  );
};
