import DukaanCard from '@/components/DukaanCard';
import FlatList from '@/components/FlatList';
import { saveSearchedProductItems, searchProductItems } from '@/redux/actions';
import { ITEM_ACTIVE_STATUS } from '@/utils/constants';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProductItem from '@/components/DesignSystem/ProductItem';

export const RenderNewSearch = (props) => {
  const { productQuery, fullSearch } = props;
  const dispatch = useDispatch();
  const [pageNo, setPageNo] = useState(1);
  const [searchText, setSearchText] = useState('');
  const storeInfo = useSelector((state: any) => state.storeReducer.store);
  const { searchedProducts } = useSelector((state: any) => state.catalogReducer);
  const { loader } = useSelector((state: any) => state.commonReducer);

  useEffect(() => {
    onSearchChangeHandle(productQuery);
  }, [productQuery]);

  const debounceOnChange = useCallback(debounce(handleSearchChange, 500), []);

  function handleSearchChange(change) {
    const text = change.trim();
    if (text && text.length >= 3) {
      dispatch(saveSearchedProductItems({ items: [], isNext: false }));
      dispatch(
        searchProductItems({
          store_id: storeInfo.store_id,
          search_text: text,
          page: 1,
        })
      );
    } else if (text && text.length === 0) {
      dispatch(saveSearchedProductItems({ items: [], isNext: 0 }));
    }
  }
  const loadMoreInit = () => {
    if (!loader) {
      dispatch(
        searchProductItems({
          store_id: storeInfo.store_id,
          search_text: searchText,
          page: pageNo + 1,
        })
      );
      setPageNo(pageNo + 1);
    }
  };

  function activeSearchedProductsItems() {
    return searchedProducts?.items?.length
      ? searchedProducts?.items.filter(
          (item) => item?.is_active === ITEM_ACTIVE_STATUS.ACTIVE
        )
      : [];
  }

  const renderFilteredSearch = () => {
    return (
      <FlatList
        isLoaderVisible={loader ? true : false}
        LoaderComponent={DukaanCard}
        loaderProps={{ isLoader: true }}
        isNext={searchedProducts.isNext ? true : false}
        onScrollEnd={() => loadMoreInit()}
        renderList={() => {
          return (
            <div
              className="search-content__box"
              style={{
                width: fullSearch ? '700px' : '100%',
                marginRight: 'auto',
                marginLeft: 'auto',
                transform: 'none',
              }}
            >
              {activeSearchedProductsItems().map((item, idx) => (
                <ProductItem
                  key={`search-item-${idx}`}
                  data={item}
                  isCartItem={false}
                  isCardType={false}
                />
              ))}
            </div>
          );
        }}
      ></FlatList>
    );
  };

  const onSearchChangeHandle = (searchText) => {
    const limitedString = searchText.substr(0, 255);
    setSearchText(searchText);
    debounceOnChange(limitedString);
  };

  const renderNotFound = () => {
    return (
      <div
        className="search-content__notfound"
        style={{
          display: 'flex',
        }}
      >
        <span>Sorry we couldn't find the item you searched</span>
      </div>
    );
  };

  return (
    <div className="tw-w-[100%] tw-bg-[#ffffff]">
      <div className="tw-flex tw-items-center tw-justify-center">
        {searchText.length > 0 ? (
          searchedProducts?.items?.length > 0 ? (
            renderFilteredSearch()
          ) : (
            renderNotFound()
          )
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
