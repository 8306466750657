import { useEffect, useRef, useState } from 'react';
import { NewHeaderComponent } from './NewHeaderComponent';
import { useWidgetDndContextData } from 'src/context/WidgetDndContext';

const header_common_class = {
  top: 0,
  zIndex: 500,
  width: '100%',
  opacity: 1,
  transition: 'top .3s ease-in-out',
};

const NewHeaderWrapper = (props : any) => {
    const [isScroll, setIsScroll] = useState(false);
    const [isScrollUp, setIsScrollUp] = useState(false);
    const headerRef = useRef();
    const lastScrollVal = useRef(0);
    const {
        widgetContextState: {
          activeHeaderData,
        },
      } = useWidgetDndContextData();
    const scrollType =
      activeHeaderData?.headerPropsConfig?.globalClass?.scrollClass?.base_config
        ?.defaultScrollType || '';  
    const customProps = {
        ...props,
        isScroll : scrollType === 'hide' ? false : isScroll,
        isScrollUp
    }

    useEffect(() => {
      if (!isScroll && isScrollUp) {
        setIsScrollUp(false);
      }
    }, [isScroll]);
  
    function handleScroll(e) {
      const val = window.scrollY;
      if (val > 20) {
        setIsScroll(true);
      } else {
        setIsScroll(false);
      }
      if (lastScrollVal.current - val > 180) {
        setIsScrollUp(true);
        lastScrollVal.current = val;
      } else if (val - lastScrollVal.current > 180) {
        setIsScrollUp(false);
        lastScrollVal.current = val;
      }
    }
    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

  const getStyleForTransparent = () => {
    const type =
      activeHeaderData?.headerPropsConfig?.globalClass?.staticClass?.base_config
        ?.defaultFillColorType;
    if (type === 'transparent') {
      return {
        position: 'fixed',
        ...header_common_class,
      };
    }
    return {
      position: 'sticky',
      ...header_common_class,
    };
  };

  const getScrollStyling = () => {
    const type =
      activeHeaderData?.headerPropsConfig?.globalClass?.staticClass?.base_config
        ?.defaultFillColorType;
    const commonClass = {
      position: type === 'transparent' ? 'fixed' : 'sticky',
      ...header_common_class,
    };
    const hideClass = {
      position: type === 'transparent' ? 'fixed' : 'sticky',
      top: -(headerRef?.current || ({} as any))?.clientHeight,
      zIndex: 5000,
      width: '100%',
      transition: 'top .3s ease-in-out',
    };
    const scrollUpClass = isScrollUp ? commonClass : hideClass;
    switch (scrollType) {
      case 'hide':
        return {
          ...hideClass,
        };
      case 'sticky':
        return {
          ...commonClass,
        };
      case 'scrollUp': {
        return {
          ...scrollUpClass,
        };
      }
      default:
        return {};
    }
  };

  return (
    <div ref={headerRef} style={isScroll ? getScrollStyling() : getStyleForTransparent()}>
      <NewHeaderComponent {...customProps} />
    </div>
  );
};

export default NewHeaderWrapper;
